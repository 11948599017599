
























































































import useAbstract from "@/use/abstract";
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  watch,
} from "@vue/composition-api";
import { AxiosInstance } from "axios";
import { v4 as uuid } from "uuid";

export default defineComponent({
  components: {
    MAbstractParticipant: () =>
      import(
        "@/components/molecules/abstracts/abstractElements/m-abstract-participant.vue"
      ),
    MAbstractLanguages: () =>
      import(
        "@/components/molecules/abstracts/abstractElements/m-abstract-languages.vue"
      ),
    MAbstractType: () =>
      import(
        "@/components/molecules/abstracts/abstractElements/m-abstract-type.vue"
      ),
    MAbstractAuthors: () =>
      import(
        "@/components/molecules/abstracts/abstractElements/m-abstract-authors.vue"
      ),
    MAbstractConsents: () =>
      import(
        "@/components/molecules/abstracts/abstractElements/m-abstract-consents.vue"
      ),
    MAbstractTopics: () =>
      import(
        "@/components/molecules/abstracts/abstractElements/m-abstract-topics.vue"
      ),
    MAbstractSymposium: () =>
      import(
        "@/components/molecules/abstracts/abstractElements/m-abstract-symposium.vue"
      ),
  },
  setup(_, { root }) {
    const { getTypeName } = useAbstract({ root });

    const model = reactive({
      participantId: "",
      type: "presentation",
      authors: [
        {
          firstName: "",
          lastName: "",
          email: "",
          address: "",
          affiliation: "",
        },
      ],
      sessionTopic: "",
      title: "",
      keywords: "",
      short: "",
      long: "",
      symposium: "",
      file: "",
      languageVersion: "polish",
      consentsIds: [],
    });

    const state = reactive({
      loading: false,
      loaded: false,
      config: {} as any,
      file: [] as any,
      error: false as boolean | number,
      maxLength: undefined as any,
      longSummaryMaxLength: undefined as any,
      wordLimit: 0,
      longSummaryWordLimit: 0,
      isValid: false,
    });

    const getConfig = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      axiosInstance
        .get(`event/${root.$route.params.id}/abstract-config`)
        .then(({ data }) => {
          state.config = data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          state.loaded = true;
          state.loading = false;
        });
    };

    onMounted(getConfig);

    const consents = computed(() => {
      if (state.config) {
        if (state.config.consents && state.config.consents.length) {
          return state.config.consents;
        }
      } else return [];
    });

    const checkLanguageVersion = () => {
      if (state.config?.languageVersion === "english") {
        model.languageVersion = "english";
      } else {
        model.languageVersion = "polish";
      }
    };

    onMounted(checkLanguageVersion);

    const rules = {
      required: [(v: string) => !!v || `${root.$t("layout.misc.required")}`],
    };

    const getErrorMessage = (code: number) => {
      switch (code) {
        case 400:
          return `${root.$tc("layout.errors.400")}`;
        case 401:
          return `${root.$tc("layout.errors.401")}`;
        case 500:
          return `${root.$tc("panel.event.abstract.add.error500")}`;
        default:
          return `${root.$tc("layout.errors.default")}`;
      }
    };

    const onSubmit = async (action: string) => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      let file = "";
      const formData = new FormData();
      formData.append("file", state.file);

      if (state.file) {
        await axiosInstance
          .post(`file/abstract/panel/${root.$route.params.id}`, formData, {
            headers: {
              event: root.$route.params.id,
            },
          })
          .then(({ data: { id } }) => {
            file = id;
          })
          .catch((e) => console.log(e));
      }

      const data = {
        participantId: model.participantId,
        languageVersion: model.languageVersion,
        type: model.type,
        title: model.title,
        keywords: model.keywords
          .split(",")
          .map((el: string) => ({ value: el.replace(/^\s+|\s+$/g, "") })),
        short: model.short || undefined,
        authors: model.authors,
        symposium: model.symposium || undefined,
        sessionTopic: model.sessionTopic,
        fileId: file || undefined,
        long: model.long || null,
        consentsIds: model.consentsIds || [],
      };

      axiosInstance
        .post(`event/${root.$route.params.id}/abstract/system`, data, {
          headers: {
            event: root.$route.params.id,
          },
        })
        .then(() => {
          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "success",
            message: root.$tc("panel.event.abstract.add.success"),
          });
          if (action === "new") {
            model.participantId = "";
            model.type = "presentation";
            model.authors = [
              {
                firstName: "",
                lastName: "",
                email: "",
                address: "",
                affiliation: "",
              },
            ];
            model.sessionTopic = "";
            model.title = "";
            model.keywords = "";
            model.short = "";
            model.long = "";
            model.symposium = "";
            model.file = "";
            model.languageVersion = "polish";
            model.consentsIds = [];
          } else {
            root.$router.back();
          }
        })
        .catch((error) => {
          state.error = error.response.status;
          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "error",
            message: getErrorMessage(state.error as number),
          });
        });
    };

    const wordCount = (str: string, type: string) => {
      if (type === "short") {
        state.wordLimit = str.split(" ").length;
        if (
          state.config &&
          state.config.limitType === "word" &&
          state.config.maxLimit <= state.wordLimit - 1
        ) {
          state.maxLength = model.short.length;
        } else {
          state.maxLength = undefined;
        }
      } else {
        state.longSummaryWordLimit = str.split(" ").length;
        if (
          state.config &&
          state.config.longSummaryType === "word" &&
          state.config.longSummaryLimit <= state.longSummaryWordLimit - 1
        ) {
          state.longSummaryMaxLength = model.short.length;
        } else {
          state.longSummaryMaxLength = undefined;
        }
      }
    };

    watch(
      () => model.short,
      () => {
        if (state.config && state.config.limitType === "word") {
          wordCount(model.short, "short");
        }
      }
    );

    watch(
      () => model.long,
      () => {
        if (state.config && state.config.longSummaryType === "word") {
          wordCount(model.long, "long");
        }
      }
    );

    return {
      getTypeName,
      model,
      state,
      consents,
      rules,
      onSubmit,
    };
  },
});
